import { useEffect, useState } from 'react'
import { Box, Typography, colors } from '@mui/material'

import { MUI } from 'components'

import { CheckboxItemType } from './checkboxItem.types'

import { dataProcesses } from 'helpers'

const CheckboxItem = ({
  disabled,
  item,
  defaultChecked,
  shouldRender,
  handleCheck,
  prediction,
}: CheckboxItemType) => {
  const [checked, setChecked] = useState(defaultChecked)
  const itemName = item?.name

  const handleCheckItem = () => {
    handleCheck(item?.id, itemName)
    setChecked((value) => !value)
  }

  useEffect(() => {
    if (defaultChecked) setChecked(true)
  }, [defaultChecked])

  useEffect(() => {
    if (shouldRender && checked !== defaultChecked) {
      setChecked(defaultChecked)
    }
  }, [shouldRender])

  const formatedPrediction = dataProcesses.legalFrameworkFormatPrediction(
    prediction,
    !disabled
  )

  return (
    <Box display="flex">
      <Box
        mr={2}
        width="28px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body2" fontWeight={500}>
          {formatedPrediction}
        </Typography>
      </Box>
      <MUI.FormControlLabel
        $color={disabled && !checked ? colors.grey[400] : 'primary'}
        control={
          <MUI.Checkbox
            checked={checked}
            onChange={() => handleCheckItem()}
            name={itemName}
            $color={disabled && !checked ? colors.grey[400] : 'primary'}
          />
        }
        label={
          <Typography variant="body2" fontWeight={500}>
            {itemName}
          </Typography>
        }
      />
    </Box>
  )
}

CheckboxItem.defaultProps = {
  item: {},
  defaultChecked: false,
  handleCheck: () => {},
}

export default CheckboxItem
