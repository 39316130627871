import { Fragment, SyntheticEvent, useState } from 'react'
import { Hidden, Tab, TablePagination, Tabs } from '@mui/material'
import { isEmpty } from 'lodash'

import {
  FilterButton,
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
} from 'components'
import {
  LegitimateInterestFilters,
  LegitimateInterestTable,
} from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import constants from 'constants/index'
import service from 'service'

const LegitimateInterestMain = () => {
  const filter = useFilter()

  const { STATUSES_LABEL } = constants.legitimateInterest

  const [selectedTab, setSelectedTab] = useState<number[] | null>(
    STATUSES_LABEL[0].value
  )

  const handleTabChange = (event: SyntheticEvent, clickedTab: number[]) => {
    setSelectedTab(clickedTab)
  }
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, loading } = useFetch(
    service.dponet.dataProcesses.getWithLia,
    {
      params: {
        ...filter.filters,
        page,
        perPage,
        statusId: selectedTab,
      },
    },
    [filter.filters, page, perPage, selectedTab]
  )

  const dataProcessesWithLia = response?.data?.dataProcesses

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Legítimo Interesse"
        actionButton={<FilterButton setDrawerOpen={filter.setDrawerOpen} />}
      />
      <PagePaper>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {STATUSES_LABEL.map((status) => (
            <Tab key={status.label} label={status.label} value={status.value} />
          ))}
        </Tabs>
        <Fragment>
          {isEmpty(dataProcessesWithLia) ? (
            <NoData
              title={'Nenhum Legítimo Interesse foi encontrado.'}
              withoutPaper
            />
          ) : (
            <>
              <LegitimateInterestTable
                dataProcessesWithLia={dataProcessesWithLia}
              />
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={(_, page) => handleChangePage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </>
          )}
        </Fragment>
      </PagePaper>
      <LegitimateInterestFilters filter={filter} />
    </ListPageStructure>
  )
}

export default LegitimateInterestMain
