import { isBoolean, isNumber, isNil } from 'lodash'

import { formatters, legitimateInterest, dataProcesses, tickets } from 'helpers'

const formattedValue = function (
  field: string,
  value: string | number | boolean | null,
  registerType?: string
): string | number {
  if (isNil(value)) return '-'

  if (isBoolean(value)) {
    if (registerType === 'Incident' && field === 'status') {
      return value ? 'Ativo' : 'Inativo'
    }
    return value ? 'Sim' : 'Não'
  } else {
    const valueAsNumber = isNumber(value) ? value : parseFloat(value)

    switch (field) {
      case 'status_id':
        if (registerType === 'DataProcess')
          return dataProcesses.statusLabel(valueAsNumber)
        else return tickets.statusLabel(valueAsNumber)
      case 'status_updated_at':
      case 'occurred':
      case 'incident_aware':
      case 'informed_holder':
      case 'anpd_notification_date':
      case 'due_date':
      case 'start_date':
      case 'deadline_date':
        return formatters.date.ptBrFormatDate(value)
      case 'incident_datetime':
        return formatters.date.ptBrFormatDateTime(value)
      case 'answer':
        return legitimateInterest.formatAnswerField(value)
      default:
        return value
    }
  }
}

export default formattedValue
