import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import { LoadingFeedback } from 'components'

import {
  OrganizationUserEditFormType,
  ProfileOptionType,
} from './organizationUserEditForm.types'

import { useSnackbar } from 'hooks'

import schema, { OrganizationUserEditSchemaType } from './schema'
import constants from 'constants/index'
import { formatters } from 'helpers'
import service from 'service'

const OrganizationUserEditForm = ({
  onClose,
  organization,
  organizationUser,
  profileOptions,
  refresh,
}: OrganizationUserEditFormType) => {
  const { snackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizationUserEditSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: organizationUser?.user?.name || '',
      profileId: organizationUser?.profile?.id || undefined,
    },
  })

  const onSubmit = async (organizationUserData: object) => {
    try {
      setLoading(true)

      await service.dponet.organizationUser.update({
        organizationId: organization?.id,
        organizationUserId: organizationUser?.id,
        organizationUserData,
      })

      onClose()
      refresh()

      snackbar.open({
        message: 'Perfil do usuário atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const defaultProfileOptionsByKind =
    organization?.kind === constants.organizations.KIND_DPO
      ? constants.profiles.DEFAULT_DPO_PROFILES_OPTIONS
      : constants.profiles.DEFAULT_OBSERVER_PROFILES_OPTIONS

  const profiles: ProfileOptionType[] = [
    ...defaultProfileOptionsByKind,
    ...(profileOptions ? profileOptions : []),
  ]

  return (
    <Box
      id="organization-user-creation-form"
      pt={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <LoadingFeedback open={loading} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.name}
                helperText={errors?.name?.message}
                label="Nome do Usuário"
                type="text"
                variant="outlined"
                disabled
                fullWidth
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl
                fullWidth
                margin="dense"
                error={!!errors?.profileId?.message}
              >
                <InputLabel id="organization-id-select-label">
                  Perfil
                </InputLabel>
                <Select
                  {...field}
                  labelId="organization-id-select-label"
                  id="organization-id-select"
                  label="Perfil"
                >
                  {profiles?.map((profile) => (
                    <MenuItem key={profile.id} value={profile?.id}>
                      {profile.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.profileId?.message}</FormHelperText>
              </FormControl>
            )}
            name="profileId"
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default OrganizationUserEditForm
