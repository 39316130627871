import * as yup from 'yup'

const schema = yup
  .object({
    name: yup.string().required().max(100),
    profileId: yup.number().required(),
  })
  .required()

export type OrganizationUserEditSchemaType = yup.InferType<typeof schema>

export default schema
