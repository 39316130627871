import { DataProcessWithIndexType } from 'types/dataProcess.types'

import dataCollectedConstants from 'constants/dataCollected'

import {
  LegalFrameworkDataType,
  LegalFrameworkType,
  LegalFrameworksPredictType,
} from 'types/legalFramework.types'

const activePersonalDataType = (dataProcess: DataProcessWithIndexType) =>
  dataProcess?.dataCollecteds?.some(
    (dataCollected) =>
      dataCollected?.collectedFields?.some((collectedField) =>
        dataCollectedConstants.dataCollectedPersonal.includes(
          collectedField?.dataCollectedOption?.dataType
        )
      )
  )

const activePersonalSensitiveDataType = (
  dataProcess: DataProcessWithIndexType
) =>
  dataProcess?.dataCollecteds?.some(
    (dataCollected) =>
      dataCollected?.collectedFields?.some((collectedField) =>
        dataCollectedConstants.dataCollectedPersonalSensitive.includes(
          collectedField?.dataCollectedOption?.dataType
        )
      )
  )

const activeMinorDataType = (dataProcess: DataProcessWithIndexType) =>
  dataProcess.dataCollecteds.some((dataCollected) => {
    return dataCollected.titularAgeRange?.some(
      (ageRange) =>
        ageRange.id === dataCollectedConstants.CHILD_ID ||
        ageRange.id === dataCollectedConstants.ADOLESCENT_ID
    )
  })

const activeMinorSensitiveDataType = (dataProcess: DataProcessWithIndexType) =>
  activeMinorDataType(dataProcess) &&
  activePersonalSensitiveDataType(dataProcess)

const predictionByLegalFramework = (
  legalFramework: LegalFrameworkType,
  predictions: LegalFrameworksPredictType[]
) =>
  predictions.find(
    (prediction) => prediction.name === legalFramework?.name?.toLowerCase()
  )

const activeDataTypes = (dataProcess: DataProcessWithIndexType) => {
  return {
    1: activePersonalDataType(dataProcess),
    2: activePersonalSensitiveDataType(dataProcess),
    3: activeMinorDataType(dataProcess),
    4: activeMinorSensitiveDataType(dataProcess),
  }
}

const dataTypeActive = (
  dataProcess: DataProcessWithIndexType,
  dataType: LegalFrameworkDataType
) => activeDataTypes(dataProcess)[dataType]

export default {
  activeDataTypes,
  activePersonalDataType,
  activePersonalSensitiveDataType,
  activeMinorDataType,
  dataTypeActive,
  predictionByLegalFramework,
}
