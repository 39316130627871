import { useState } from 'react'
import { Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'

import { useSnackbar } from 'hooks'

import { OrganizationUserInactivationDialogType } from './organizationUserInactivationDialog.types'

import service from 'service'
import { formatters } from 'helpers'
import organizationUsersConstants from 'constants/organizationUsers'

const OrganizationUserInactivationDialog = ({
  open,
  onClose,
  organization,
  organizationUser,
  refreshOrganizationUsers,
}: OrganizationUserInactivationDialogType) => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()

  const chageUserOrganizationStatus = async () => {
    if (!organizationUser || !organization?.id) return

    try {
      setLoading(true)

      await service.dponet.organizationUser.changeStatus({
        organizationId: organization?.id,
        organizationUserId: organizationUser?.id,
        status: organizationUsersConstants.INACTIVE_STATUS,
      })

      refreshOrganizationUsers()

      snackbar.open({
        message: 'Usuário inativado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      onClose()
    }
  }

  return (
    <BasicDialog
      open={open}
      title="Inativar Usuário"
      onClose={onClose}
      dialogActions={
        <Button
          variant="contained"
          color="error"
          onClick={chageUserOrganizationStatus}
        >
          INATIVAR USUÁRIO
        </Button>
      }
    >
      <LoadingFeedback open={loading} />

      <Typography variant="body1">
        Você tem certeza que deseja inativar o usuário&nbsp;
        <strong>{organizationUser?.user?.name}</strong>?
      </Typography>
    </BasicDialog>
  )
}

export default OrganizationUserInactivationDialog
