import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTreat = api.create('dponet', false)

const create = async ({
  organizationId,
  organizationUser,
}: {
  organizationId?: string | number
  organizationUser: object
}) => {
  return dponetAPI.post(`/advisors/organizations/${organizationId}/users`, {
    organizationUser,
  })
}

const get = async ({
  organizationId = '',
  params = {},
}: {
  organizationId?: string | number
  params?: object
}) => {
  return dponetAPI.get(`/advisors/organizations/${organizationId}/users`, {
    params,
  })
}

const update = async ({
  organizationId,
  organizationUserId,
  organizationUserData,
}: {
  organizationId?: string | number
  organizationUserId?: string | number
  organizationUserData: object
}) => {
  return dponetAPI.put(
    `/advisors/organizations/${organizationId}/users/${organizationUserId}`,
    {
      organizationUserData,
    }
  )
}

const changeStatus = async ({
  organizationId,
  organizationUserId,
  status,
}: {
  organizationId?: string | number
  organizationUserId?: string | number
  status: string
}) => {
  return dponetAPI.patch(
    `/advisors/organizations/${organizationId}/users/${organizationUserId}/change_status`,
    {
      status,
    }
  )
}

const replyInvite = async ({
  organizationId,
  organizationUserId,
  status,
}: {
  organizationId?: string | number
  organizationUserId?: string | number
  status: string
}) => {
  return dponetAPINotTreat.patch(
    `/advisors/organizations/${organizationId}/users/${organizationUserId}/reply_invite`,
    {
      status,
    }
  )
}

const enableAccessCompanyDashboard = async ({
  organizationId,
  organizationUserId,
}: {
  organizationId: string | number
  organizationUserId: string | number
}) => {
  return await dponetAPINotTreat.patch(
    `/advisors/organizations/${organizationId}/users/${organizationUserId}/access_company_dashboard`
  )
}

export default {
  changeStatus,
  create,
  enableAccessCompanyDashboard,
  get,
  replyInvite,
  update,
}
