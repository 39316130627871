import { Button } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'
import { OrganizationUserEditForm } from './components'

import { useFetch } from 'hooks'

import service from 'service'

import { OrganizationUserEditDialogType } from './organizationUserEditDialog.types'

const OrganizationUserEditDialog = ({
  open,
  onClose,
  organization,
  organizationUser,
  refreshOrganizationUsers,
}: OrganizationUserEditDialogType) => {
  const { response, loading } = useFetch(
    service.dponet.profiles.get,
    {
      params: {
        active: true,
        perPage: 999,
        organizationId: organization?.id,
      },
    },
    [open]
  )

  const profileOptions = response?.data?.profiles

  return (
    <BasicDialog
      open={open}
      title="Editar perfil do usuário"
      onClose={onClose}
      dialogActions={
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form="organization-user-creation-form"
        >
          SALVAR
        </Button>
      }
    >
      <LoadingFeedback open={loading} />

      <OrganizationUserEditForm
        onClose={onClose}
        organization={organization}
        organizationUser={organizationUser}
        profileOptions={profileOptions}
        refresh={refreshOrganizationUsers}
      />
    </BasicDialog>
  )
}

export default OrganizationUserEditDialog
