import { Fragment, SyntheticEvent, useState, useEffect } from 'react'
import { Hidden, Tab, TablePagination, Tabs } from '@mui/material'
import { isNil, isEmpty, isEqual } from 'lodash'

import {
  FilterButton,
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
} from 'components'
import { TicketTable } from 'components/Tickets'

import { useFetch, useFilter, usePagination, useOrganization } from 'hooks'

import service from 'service'
import ticketsConstants from 'constants/tickets'
import { LegalTicketFilter } from './components'

const LegalTicketMain = () => {
  const filter = useFilter()
  const [selectedTab, setSelectedTab] = useState<number | null>(
    ticketsConstants.TICKET_CHAT_STATUS_TABS[0].id
  )

  const { currentOrganizationUser } = useOrganization()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const filterStatus = () => {
    if (!isNil(filter.filters?.statusId)) return filter.filters?.statusId

    if (!isEmpty(filter.filters)) return ticketsConstants.ALL_TICKETS_ID

    return selectedTab
  }

  const { response, loading } = useFetch(
    service.dponet.legalTickets.getAll,
    {
      ...filter.filters,
      page,
      perPage,
      statusId: filterStatus(),
    },
    [filter.filters, page, perPage, selectedTab]
  )

  const getTab = () => {
    if (!isNil(filter.filters?.statusId)) {
      if (!isNil(filter.filters?.responsibleId)) return
      return setSelectedTab(Number(filter.filters?.statusId))
    }

    if (
      !isNil(filter.filters?.responsibleId) &&
      isEqual(
        Number(filter.filters?.responsibleId),
        currentOrganizationUser?.user?.id
      )
    ) {
      return setSelectedTab(ticketsConstants.MY_SOLICITATION_ID)
    }

    if (!isEmpty(filter.filters)) {
      return setSelectedTab(ticketsConstants.ALL_TICKETS_ID)
    }
  }

  useEffect(() => {
    getTab()
  }, [filter.filters])

  const solicitationTickets = response?.data?.tickets ?? []

  const handleTabChange = (event: SyntheticEvent, clickedTab: number) => {
    if (clickedTab === ticketsConstants.MY_SOLICITATION_ID) {
      filter.setFilters({ responsibleId: currentOrganizationUser?.user?.id })
      return setSelectedTab(clickedTab)
    }
    filter.setFilters({})
    setSelectedTab(clickedTab)
  }

  const filteredTabs = ticketsConstants.TICKET_CHAT_STATUS_TABS.filter(
    (tab) => tab.id !== ticketsConstants.WAITING_CONFIRMATION_TICKET_ID
  )

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Solicitações"
        actionButton={<FilterButton setDrawerOpen={filter.setDrawerOpen} />}
      />
      <PagePaper>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {filteredTabs.map((tab) => (
            <Tab key={tab.id} label={tab.tabName} value={tab.id} />
          ))}
        </Tabs>
        {loading || isEmpty(solicitationTickets) ? (
          <NoData title="Nenhuma solicitação foi encontrada." withoutPaper />
        ) : (
          <Fragment>
            <TicketTable
              selectedTab={selectedTab}
              solicitationTickets={solicitationTickets}
              isLegalRequest={true}
            />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
      </PagePaper>
      <LegalTicketFilter filter={filter} />
    </ListPageStructure>
  )
}

export default LegalTicketMain
