const legalFrameworkFormatPrediction = (
  prediction?: string,
  enabled?: boolean
) => {
  if (!!prediction && enabled) {
    return `${(parseFloat(prediction) * 100)
      .toFixed(0)
      .toString()
      .padStart(2, '0')}%`
  }

  return '-'
}

export default legalFrameworkFormatPrediction
