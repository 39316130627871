import { Fragment, SyntheticEvent, useState } from 'react'
import { Box, Hidden, Tab, TablePagination, Tabs, Tooltip } from '@mui/material'
import { isEmpty } from 'lodash'
import { Info as InfoIcon } from 'react-feather'

import {
  FilterButton,
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
} from 'components'
import { TicketTable } from 'components/Tickets'
import { TicketFilter } from './components'

import { useFetch, useFilter, usePagination, useOrganization } from 'hooks'

import service from 'service'
import ticketsConstants from 'constants/tickets'
import organizationConstants from 'constants/organizations'

const TicketMain = () => {
  const filter = useFilter()
  const { currentOrganization } = useOrganization()
  const { KIND_OBSERVER } = organizationConstants

  const isObserver = currentOrganization?.kind === KIND_OBSERVER

  const [selectedTab, setSelectedTab] = useState<number | null>(
    ticketsConstants.ALL_TICKETS_ID
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ resetPage: selectedTab })

  const { response, loading } = useFetch(
    service.dponet.ticket.list,
    {
      params: {
        ...filter.filters,
        page,
        perPage,
        ticketType:
          selectedTab === ticketsConstants.ANPD_TICKET_ID
            ? ticketsConstants.ANPD_TICKET_KIND
            : '',
        statuses_ids: selectedTab
          ? ticketsConstants.STATUSES_BY_TAB[selectedTab]
          : undefined,
      },
    },
    [filter.filters, page, perPage, selectedTab]
  )

  const solicitationTickets = response?.data?.tickets ?? []

  const handleTabChange = (_: SyntheticEvent, clickedTab: number) =>
    setSelectedTab(clickedTab)

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Solicitações"
        actionButton={<FilterButton setDrawerOpen={filter.setDrawerOpen} />}
      />
      <PagePaper>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {ticketsConstants.PRIVACY_PORTAL_TICKET_STATUS_TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={
                <Box display="flex" gap={1}>
                  {tab.tabName}
                  <Tooltip title={tab.info}>
                    <InfoIcon size={15} />
                  </Tooltip>
                </Box>
              }
              value={tab.id}
            />
          ))}
        </Tabs>
        {loading || isEmpty(solicitationTickets) ? (
          <NoData
            title={
              isObserver
                ? 'Você não possui permissões de visualização autorizadas pelo DPO responsável pela organização.'
                : 'Nenhuma solicitação foi encontrada.'
            }
            withoutPaper
          />
        ) : (
          <Fragment>
            <TicketTable
              selectedTab={selectedTab}
              solicitationTickets={solicitationTickets}
              isLegalRequest={false}
            />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
      </PagePaper>
      <TicketFilter filter={filter} />
    </ListPageStructure>
  )
}

export default TicketMain
