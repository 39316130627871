import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { reverse } from 'named-urls'

import { BasicDialog } from 'components'

import { OrganizationCreateType } from './organizationDestroy.types'

import { useSnackbar } from 'hooks'

import service from 'service'
import routes from 'constants/routes'
import { formatters } from 'helpers'

const OrganizationDestroy = ({
  refresh,
  setLoading,
  organization,
}: OrganizationCreateType) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const navigate = useNavigate()
  const { snackbar } = useSnackbar()

  const organizationHasCompanies = (organization?.companies ?? 0) > 0
  const isDpo = organization?.kind === 'DPO'

  const deleteOrganization = async () => {
    try {
      setLoading(true)

      await service.dponet.organization.deleteOrganization({
        organizationId: organization?.id,
      })
      refresh()
      navigate(reverse(routes.app.organizations.all))
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleOpenDestroyDialog = () => setOpenDeleteDialog(true)
  const handleCloseDestroyDialog = () => setOpenDeleteDialog(false)

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={handleOpenDestroyDialog}
        aria-label="abrir modal para excluir grupo"
      >
        EXCLUIR GRUPO
      </Button>
      <BasicDialog
        open={openDeleteDialog}
        title="Excluir Grupo"
        onClose={handleCloseDestroyDialog}
        dialogActions={
          <>
            <Button color="primary" onClick={handleCloseDestroyDialog}>
              {isDpo && organizationHasCompanies ? 'FECHAR' : 'CANCELAR'}
            </Button>
            {!(isDpo && organizationHasCompanies) && (
              <Button
                color="error"
                variant="contained"
                onClick={deleteOrganization}
              >
                EXCLUIR
              </Button>
            )}
          </>
        }
      >
        {
          <>
            {isDpo && organizationHasCompanies ? (
              <Typography variant="body1">
                Atenção! Existem organizações vinculadas a este grupo.
                <br />
                <br />
                Recomendamos indicar um novo encarregado antes de efetuar a
                exclusão, para garantir que essas organizações não fiquem sem um
                DPO nomeado. <br />
              </Typography>
            ) : (
              <Typography variant="body1">
                Você tem certeza que quer excluir esse Grupo Advisor? <br />
                Isso fará com que todos os vínculos com o Grupo sejam perdidos.
                <br />
                Essa ação <strong>NÃO</strong> poderá ser desfeita
              </Typography>
            )}
          </>
        }
      </BasicDialog>
    </>
  )
}

OrganizationDestroy.defaultProps = {
  refresh: () => {},
}

export default OrganizationDestroy
