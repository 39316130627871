import { useState } from 'react'
import { Box, Button, Hidden, Paper, TablePagination } from '@mui/material'
import { Check as CheckIcon } from 'react-feather'

import { ConditionalLoading, PaperHeader } from 'components'
import {
  OrganizationUserInviteDialog,
  OrganizationUserTable,
} from './components'
import PermittedOrganization from '../PermittedOrganization'

import { useFetch, usePagination } from 'hooks'

import {
  OrganizationUserResponseType,
  OrganizationUserType,
} from './organizationUser.types'

import service from 'service'
import permissionTagsConstants from 'constants/permissionTags'

const OrganizationUser = ({
  organizationId,
  permissions,
  organization,
}: OrganizationUserType) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false)

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true)
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false)
  }

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const {
    response: responseOrganizationUsers,
    loading: loadingOrganizationUsers,
    refresh: refreshOrganizationUsers,
  } = useFetch<OrganizationUserResponseType>(
    service.dponet.organizationUser.get,
    {
      organizationId,
      params: { page, perPage, organizationId },
    },
    [page, perPage]
  )

  const organizationUsers =
    responseOrganizationUsers?.data?.organizationUsers || []

  return (
    <Paper component={Box} p={2} mb={6}>
      <PaperHeader
        title="Usuários"
        actionButton={
          <PermittedOrganization
            tag={permissionTagsConstants.ORGANIZATION_USER.MANAGE}
            permissions={permissions}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CheckIcon size={20} />}
              onClick={handleOpenCreateDialog}
            >
              CONVIDAR USUÁRIO
            </Button>
          </PermittedOrganization>
        }
      />

      <ConditionalLoading loading={loadingOrganizationUsers} centered>
        <OrganizationUserTable
          organizationUsers={organizationUsers}
          organization={organization}
          permissions={permissions}
          refreshOrganizationUsers={refreshOrganizationUsers}
        />
        <TablePagination
          component="div"
          count={responseOrganizationUsers?.data?.meta?.totalCount || 0}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </ConditionalLoading>

      <OrganizationUserInviteDialog
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
        organization={organization}
        refreshOrganizationUsers={refreshOrganizationUsers}
      />
    </Paper>
  )
}

export default OrganizationUser
