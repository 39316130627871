import { Box, Grid, Link, Typography, colors } from '@mui/material'

import { MUI } from 'components'

import { PreviewPageType } from './previewPage.types'

const PreviewPage = ({
  primaryColor,
  secondaryColor,
  primaryTextColor,
  secondaryTextColor,
  showHeader,
  showFooter,
}: PreviewPageType) => {
  return (
    <Box bgcolor={colors.grey['A100']} boxShadow={1} borderRadius={1}>
      <Box display="flex" flexDirection="column" gap={5} p={5}>
        <Box display="flex" flexDirection="column">
          {/* Header */}
          <MUI.Box
            bgcolor={primaryColor}
            $borderTopRightRadius={5}
            $borderTopLeftRadius={5}
            boxShadow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            pt={3}
            pb={6}
          >
            {showHeader && (
              <Typography
                variant="body1"
                fontWeight={500}
                textTransform="uppercase"
                color={primaryTextColor}
                pb={3}
              >
                Cabeçalho
              </Typography>
            )}
            <Box width="100%" px={8}>
              <MUI.Box
                bgcolor={secondaryColor}
                $borderTopLeftRadius={5}
                $borderTopRightRadius={5}
                p={4}
              >
                <Typography
                  color={secondaryTextColor}
                  variant="body2"
                  textAlign="center"
                >
                  Esta organização <strong>é amiga da privacidade&nbsp;</strong>
                </Typography>
                <Typography
                  color={secondaryTextColor}
                  variant="body2"
                  textAlign="center"
                >
                  em relação à <strong>Lei Geral de Proteção de Dados</strong>
                </Typography>
              </MUI.Box>
              <MUI.Box
                bgcolor="white"
                $borderBottomLeftRadius={5}
                $borderBottomRightRadius={5}
                boxShadow={1}
                display="flex"
                flexDirection="row"
                p={4}
              >
                <Grid container spacing={4} pt={4} pl={4}>
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    width="100%"
                    bgcolor={secondaryColor}
                    borderRadius={1}
                  />
                  <Grid
                    item
                    xs={7}
                    sm={9}
                    display="flex"
                    flexDirection="column"
                    gap={4}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      Dados da empresa
                    </Typography>
                    <Typography variant="body2">DPO responsável</Typography>
                  </Grid>
                </Grid>
              </MUI.Box>
            </Box>
          </MUI.Box>
          {/* Body */}
          <Box bgcolor="white" boxShadow={1}>
            <Grid container spacing={8} p={4} px={8}>
              {Array.from({ length: 2 }, (_, index) => (
                <Grid item xs={6} key={index}>
                  <Box boxShadow={1} borderRadius={1} p={5} pt={18}>
                    <MUI.Box
                      boxShadow={1}
                      borderRadius={1}
                      bgcolor={secondaryColor}
                      $cursor="pointer"
                      p={1}
                    >
                      <MUI.Typography
                        variant="body1"
                        color={secondaryTextColor}
                        textAlign="center"
                        textTransform="uppercase"
                        fontWeight={500}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        $wordBreak="break-all"
                      >
                        Botão
                      </MUI.Typography>
                    </MUI.Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* Rodapé */}
          <MUI.Box
            $borderBottomLeftRadius={5}
            $borderBottomRightRadius={5}
            bgcolor={primaryColor}
            boxShadow={1}
            p={4}
          >
            {showFooter && (
              <Typography
                variant="body1"
                fontWeight={500}
                textTransform="uppercase"
                textAlign="center"
                color={primaryTextColor}
              >
                Rodapé
              </Typography>
            )}
          </MUI.Box>
        </Box>

        <Typography variant="body2" textAlign="center">
          Visualização de como ficará exibição do canal no&nbsp;
          <strong>
            <Link
              href="https://www.privacidade.com.br/"
              target="_blank"
              color={colors.common.black}
            >
              privacidade.com.br
            </Link>
          </strong>
        </Typography>
      </Box>
    </Box>
  )
}

export default PreviewPage
