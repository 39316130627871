import { useState } from 'react'
import {
  Chip,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as TableRowDefault,
} from '@mui/material'

import {
  LoadingFeedback,
  MenuButton,
  Table as TableComponent,
  TableRow,
} from 'components'
import { PermittedOrganization } from 'views/Organization/components'
import {
  OrganizationUserEditDialog,
  OrganizationUserInactivationDialog,
} from '../index'

import { OrganizationUserTableType } from './organizationUserTable.types'
import { OrganizationUserType } from 'types/organization.types'

import { useOrganization, useSnackbar } from 'hooks'

import {
  formatters,
  organizationUsers as organizationUsersHelper,
} from 'helpers'
import organizationUsersConstants from 'constants/organizationUsers'
import permissionTagsConstants from 'constants/permissionTags'
import service from 'service'
import { ProfileFunctionalitiesType } from 'types/profile.types'
import { PermissionAccessClientPortalDialog } from './components'

const OrganizationUsersTable = ({
  organizationUsers,
  organization,
  permissions,
  refreshOrganizationUsers,
}: OrganizationUserTableType) => {
  const [selectedOrganizationUser, setSelectedOrganizationUser] = useState<
    OrganizationUserType | undefined
  >()
  const [selectedOrganizationEditUser, setSelectedOrganizationEditUser] =
    useState<OrganizationUserType | undefined>()
  const [openDialogPermission, setOpenDialogPermission] = useState(false)
  const [loadingClientPortalAccess, setLoadingClientPortalAccess] =
    useState(false)

  const { currentOrganization } = useOrganization()
  const { snackbar } = useSnackbar()

  const organizationId = organization?.id
  const sameOrganization = currentOrganization?.id === organizationId

  const handleCloseInactivationDialog = () => {
    setSelectedOrganizationUser(undefined)
  }

  const handleOpenInactivationDialog = (
    organizationUser: OrganizationUserType
  ) => {
    setSelectedOrganizationUser(organizationUser)
  }

  const handleCloseDialogPermission = () => {
    setOpenDialogPermission(false)
  }

  const handleEnableDashboardClient = async (
    organizationUser: OrganizationUserType
  ) => {
    try {
      if (organizationId && organizationUser?.id) {
        await service.dponet.organizationUser.enableAccessCompanyDashboard({
          organizationId: organizationId,
          organizationUserId: organizationUser?.id,
        })
        refreshOrganizationUsers()
        setLoadingClientPortalAccess(false)
      }
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro, tente novamente mais tarde.',
        variant: 'error',
      })
    }
  }

  const checkPermissionListCompanies = async (
    organizationUser: OrganizationUserType
  ) => {
    setLoadingClientPortalAccess(true)

    let profileFunctsOrganizationUser: ProfileFunctionalitiesType[] = []

    try {
      if (!organizationUser?.clientPortalAccess) {
        const response = await service.dponet.user.permissions({
          params: {
            organizationUserId: organizationUser.id,
          },
        })

        profileFunctsOrganizationUser = response?.data
          ?.profilePermissions as ProfileFunctionalitiesType[]
      }

      const tags = [
        permissionTagsConstants.ORGANIZATION_COMPANY.MANAGE,
        permissionTagsConstants.ORGANIZATION_COMPANY.VIEW,
      ]

      const hasPermission = (tag: string) => {
        return (
          profileFunctsOrganizationUser?.find(
            (profileFuncts) => profileFuncts.tag === tag
          )?.status ?? true
        )
      }

      const somePermitted = tags.map((tag) => hasPermission(tag)).includes(true)

      if (!somePermitted) {
        setLoadingClientPortalAccess(false)
        return setOpenDialogPermission(true)
      }

      handleEnableDashboardClient(organizationUser)
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro, tente novamente mais tarde.',
        variant: 'error',
      })
    } finally {
      setLoadingClientPortalAccess(false)
    }
  }

  const handleOpenUserEditDialog = (user: OrganizationUserType) => {
    setSelectedOrganizationEditUser(user)
  }

  const handleCloseOpenUserEditDialog = () => {
    setSelectedOrganizationEditUser(undefined)
  }

  return (
    <>
      <LoadingFeedback open={loadingClientPortalAccess} />
      <TableContainer>
        <TableComponent aria-label="listagem dos grupos advisors">
          <TableHead>
            <TableRowDefault>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Criado em</TableCell>
              <TableCell align="left">Perfil</TableCell>
              <TableCell align="center">Status</TableCell>
              <PermittedOrganization
                tag={permissionTagsConstants.ORGANIZATION_USER.MANAGE}
                permissions={permissions}
              >
                {sameOrganization && <TableCell align="right">Ação</TableCell>}
              </PermittedOrganization>
            </TableRowDefault>
          </TableHead>
          <TableBody>
            {organizationUsers.map((organizationUser) => (
              <TableRow key={organizationUser.id}>
                <TableCell align="left">
                  {organizationUser?.user?.name}
                </TableCell>
                <TableCell align="left">
                  {organizationUser?.user?.email}
                </TableCell>
                <TableCell align="left">
                  {formatters.date.ptBrFormatDate(organizationUser.createdAt)}
                </TableCell>
                <TableCell align="left">
                  {organizationUser?.profile?.name}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={organizationUsersHelper.statusTranslation(
                      organizationUser?.status
                    )}
                    color={organizationUsersHelper.statusColor(
                      organizationUser?.status
                    )}
                    size="small"
                    variant="filled"
                  />
                </TableCell>
                <PermittedOrganization
                  tag={permissionTagsConstants.ORGANIZATION_USER.MANAGE}
                  permissions={permissions}
                >
                  <TableCell align="right">
                    {sameOrganization &&
                      organizationUser?.status !==
                        organizationUsersConstants.INACTIVE_STATUS && (
                        <MenuButton
                          id={`user-organization-${organizationUser?.id}`}
                        >
                          <MenuItem
                            onClick={() =>
                              handleOpenInactivationDialog(organizationUser)
                            }
                          >
                            INATIVAR
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleOpenUserEditDialog(organizationUser)
                            }
                          >
                            EDITAR
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              checkPermissionListCompanies(organizationUser)
                            }
                          >
                            {organizationUser?.clientPortalAccess
                              ? 'DESATIVAR '
                              : 'ATIVAR '}
                            ACESSO AO PORTAL
                          </MenuItem>
                        </MenuButton>
                      )}
                  </TableCell>
                </PermittedOrganization>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>
      <PermissionAccessClientPortalDialog
        open={openDialogPermission}
        onClose={handleCloseDialogPermission}
      />
      <OrganizationUserInactivationDialog
        open={!!selectedOrganizationUser}
        onClose={handleCloseInactivationDialog}
        organization={organization}
        organizationUser={selectedOrganizationUser}
        refreshOrganizationUsers={refreshOrganizationUsers}
      />
      <OrganizationUserEditDialog
        open={!!selectedOrganizationEditUser}
        onClose={handleCloseOpenUserEditDialog}
        organization={organization}
        organizationUser={selectedOrganizationEditUser}
        refreshOrganizationUsers={refreshOrganizationUsers}
      />
    </>
  )
}

export default OrganizationUsersTable
