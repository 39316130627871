import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material'
import { orderBy, uniq } from 'lodash'
import { ChevronDown as ChevronDownIcon } from 'react-feather'

import { CheckboxItem } from './components'

import {
  LegalFrameworkDataType,
  LegalFrameworkType,
} from 'types/legalFramework.types'
import { LegalFrameworksChecklistType } from './legalFrameworksChecklist.types'

import theme from 'theme'
import legalFrameworksHelper from 'helpers/dataProcesses/legalFrameworks'
import legalFrameworkConstants from 'constants/legalFramework'

const LegalFrameworksChecklist = ({
  dataProcess,
  legalFrameworkByDataTypes,
  selectedLegalFrameworks,
  setSelectedLegalFrameworks,
  selectedLegalFrameworksNames,
  setSelectedLegalFrameworksNames,
}: LegalFrameworksChecklistType) => {
  const predictions = dataProcess?.predictions
  const [shouldRenderCheckbox, setShouldRenderCheckbox] = useState(false)

  useEffect(() => {
    if (shouldRenderCheckbox) {
      setShouldRenderCheckbox(false)
    }
  }, [shouldRenderCheckbox])

  const dataTypeActive = (dataType: LegalFrameworkDataType) =>
    legalFrameworksHelper.dataTypeActive(dataProcess, dataType)

  const handleSelectAll = (items: LegalFrameworkType[] = []) => {
    setSelectedLegalFrameworksNames(
      uniq([...selectedLegalFrameworksNames, ...items.map((item) => item.name)])
    )
    setSelectedLegalFrameworks(
      uniq([...selectedLegalFrameworks, ...items.map((item) => item.id)])
    )
  }

  const handleCheckItem = (id: number, name: string) => {
    const itemExists = !!selectedLegalFrameworks?.find((item) => item === id)
    const ids = [id]
    const names = [name]

    if (name === legalFrameworkConstants.CONSENT) {
      for (const dataType of legalFrameworkConstants.DATA_TYPES_MINOR_VALUES) {
        const dataTypeValue =
          dataType === 'minor'
            ? legalFrameworkConstants.TYPE_MINOR
            : legalFrameworkConstants.TYPE_MINOR_SENSITIVE

        if (dataTypeActive(dataTypeValue as LegalFrameworkDataType)) {
          const idConsent = legalFrameworkByDataTypes?.[dataType]?.items?.find(
            (item) => item?.name === legalFrameworkConstants.PARENTAL_CONSENT
          )?.id
          if (idConsent) {
            ids.push(idConsent)
            names.push(legalFrameworkConstants.PARENTAL_CONSENT)
          }
        }
      }
    }

    if (itemExists) {
      setSelectedLegalFrameworksNames(
        selectedLegalFrameworksNames?.filter((item) => item !== name)
      )
      setSelectedLegalFrameworks(
        selectedLegalFrameworks?.filter((item) => !ids.includes(item))
      )
    } else {
      setSelectedLegalFrameworksNames(
        uniq([...selectedLegalFrameworksNames, ...names])
      )
      setSelectedLegalFrameworks(uniq([...selectedLegalFrameworks, ...ids]))
    }

    setShouldRenderCheckbox(ids.length > 1)
  }

  const definePrediction = (legalFramework: LegalFrameworkType) => {
    return legalFrameworksHelper.predictionByLegalFramework(
      legalFramework,
      predictions
    )?.prediction
  }

  const isLegalFrameworkDefaultChecked = (
    legalFramework: LegalFrameworkType
  ) => {
    const prediction = legalFrameworksHelper.predictionByLegalFramework(
      legalFramework,
      predictions
    )?.prediction

    const activeDataType = legalFrameworksHelper.dataTypeActive(
      dataProcess,
      legalFramework.dataType
    )
    return prediction && parseFloat(prediction) > 0.7 && activeDataType
  }

  useEffect(() => {
    legalFrameworkConstants.DATA_TYPES_VALUES?.map((dataType) => {
      const legalFrameworks = legalFrameworkByDataTypes[dataType]?.items || []

      legalFrameworks.map((legalFramework) => {
        if (isLegalFrameworkDefaultChecked(legalFramework)) {
          setSelectedLegalFrameworksNames((selectedLegalFrameworksNames) => [
            ...selectedLegalFrameworksNames,
            legalFramework.name,
          ])
          setSelectedLegalFrameworks((selectedLegalFrameworks) => [
            ...selectedLegalFrameworks,
            legalFramework.id,
          ])
        }
      })
    })
  }, [predictions, legalFrameworkByDataTypes])

  return (
    <>
      {orderBy(legalFrameworkByDataTypes, 'order')?.map(
        (legalFrameworkDataType) => (
          <Accordion
            key={legalFrameworkDataType?.label}
            variant="outlined"
            defaultExpanded={dataTypeActive(
              legalFrameworkDataType.items[0]?.dataType
            )}
          >
            <AccordionSummary expandIcon={<ChevronDownIcon />}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={5.5}
              >
                <Typography variant="body1" fontWeight="bold">
                  {legalFrameworkDataType?.label}
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => handleSelectAll(legalFrameworkDataType?.items)}
                >
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    textTransform="capitalize"
                    color={theme.palette.secondary.light}
                  >
                    Selecionar todos
                  </Typography>
                </Button>
              </Box>
            </AccordionSummary>
            <Divider />
            <Box ml={2}>
              {orderBy(legalFrameworkDataType?.items, 'id')?.map(
                (legalFramework) => (
                  <CheckboxItem
                    key={legalFramework?.id}
                    prediction={definePrediction(legalFramework)}
                    item={legalFramework}
                    shouldRender={
                      shouldRenderCheckbox &&
                      legalFramework.name ===
                        legalFrameworkConstants.PARENTAL_CONSENT
                    }
                    defaultChecked={selectedLegalFrameworks.includes(
                      legalFramework?.id
                    )}
                    handleCheck={handleCheckItem}
                    disabled={!dataTypeActive(legalFramework.dataType)}
                  />
                )
              )}
            </Box>
          </Accordion>
        )
      )}
    </>
  )
}

LegalFrameworksChecklist.defaultProps = {
  predictions: [],
  legalFrameworkByDataTypes: [],
  selectedLegalFrameworks: [],
  setSelectedLegalFrameworks: () => {},
}

export default LegalFrameworksChecklist
