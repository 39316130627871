import { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { BasicDialog, LoadingFeedback } from 'components'
import {
  ReasonOutsourcedDpoDialog,
  SelectOrganizationDialog,
} from './components'
import { CompanySection } from 'views/Company/components'

import { useSnackbar, useAuth, useOrganization } from 'hooks'

import { ReasonOutsourcedDpoDialogSchemaType } from './components/ReasonOutsourcedDpoDialog/reasonOutsourcedDpoDialogSchema'
import { SelectOrganizationDialogSchemaType } from './components/SelectOrganizationDialog/selectOrganizationDialogSchema'

import service from 'service'
import { formatters } from 'helpers'
import routes from 'constants/routes'
import outsourcedDposConstants from 'constants/outsourcedDpos'
import observersConstants from 'constants/observers'

const InviteOutsourcedDpo = () => {
  const [loading, setLoading] = useState(false)
  const [openAcceptedDialog, setOpenAcceptedDialog] = useState(false)
  const [openRefuseDialog, setOpenRefuseDialog] = useState(false)

  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const { user, loadData } = useAuth()
  const { loadedOrganizations, loadOrganizations } = useOrganization()

  const pendingObservers =
    user?.observers?.filter(
      (observer) => observer.status === observersConstants.PENDING_STATUS
    ) ?? []
  const pendingObserver = pendingObservers[0]

  const changeInviteStatus = (status: string) => {
    return async (
      data:
        | SelectOrganizationDialogSchemaType
        | ReasonOutsourcedDpoDialogSchemaType
    ) => {
      try {
        setLoading(true)

        await service.dponet.observer.changeStatus({
          observerInviteId: pendingObserver?.id,
          observerInvite: {
            status,
            ...('organizationId' in data && {
              organizationId: data.organizationId,
            }),
            ...('reason' in data && {
              reason:
                data?.reason !== 'another' ? data?.reason : data?.anotherReason,
            }),
          },
        })

        snackbar.open({
          message: 'Convite atualizado com sucesso!',
          variant: 'success',
        })
        await loadOrganizations()
        await loadData()
        navigate(routes.app.companies.all)
      } catch (error) {
        console.error(error)

        snackbar.open({
          message: formatters.errorMessage(error),
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const handleOpenRefuseInviteDialog = () => setOpenRefuseDialog(true)
  const handleCloseRefuseInviteDialog = () => setOpenRefuseDialog(false)

  const handleOpenAcceptedInviteDialog = () => setOpenAcceptedDialog(true)
  const handleCloseAcceptedInviteDialog = () => setOpenAcceptedDialog(false)

  const handleAcceptInvite = (data: SelectOrganizationDialogSchemaType) => {
    changeInviteStatus(outsourcedDposConstants.ACTIVE_STATUS)(data)
  }

  const handleRefuseInvite = (data: ReasonOutsourcedDpoDialogSchemaType) => {
    changeInviteStatus(outsourcedDposConstants.REFUSED_STATUS)(data)
  }

  return (
    <BasicDialog
      open
      title={`Você foi convidado para participar como Observador da ${pendingObserver?.company?.name} `}
      onClose={() => {}}
      maxWidth="md"
      dialogActions={
        <>
          <Button
            variant="contained"
            color="error"
            onClick={handleOpenRefuseInviteDialog}
          >
            RECUSAR CONVITE
          </Button>
          <Button
            variant="contained"
            color="tertiary"
            onClick={handleOpenAcceptedInviteDialog}
          >
            ACEITAR CONVITE
          </Button>
        </>
      }
    >
      <LoadingFeedback open={loading} />

      <Typography
        variant="body1"
        fontWeight={500}
        textAlign="justify"
        gutterBottom
      >
        Bem-vindo(a)! Estamos empolgados por tê-lo(a) a bordo como Observador.
      </Typography>

      <Typography variant="body2" textAlign="justify" gutterBottom>
        Para começar a utilizar todas as funcionalidades e recursos que nossa
        plataforma oferece, é necessário participar de um área de trabalho,
        chamado &quot;<strong>Grupo Advisor Observador</strong>&quot;. Essa área
        será o ponto central das suas atividades e interações com outros
        colaboradores.
      </Typography>

      <CompanySection company={pendingObserver?.company} />

      <ReasonOutsourcedDpoDialog
        open={openRefuseDialog}
        onClose={handleCloseRefuseInviteDialog}
        onSave={handleRefuseInvite}
        company={pendingObserver?.company}
        observer
      />

      {loadedOrganizations && (
        <SelectOrganizationDialog
          open={openAcceptedDialog}
          onClose={handleCloseAcceptedInviteDialog}
          onSave={handleAcceptInvite}
          observer
        />
      )}
    </BasicDialog>
  )
}

export default InviteOutsourcedDpo
